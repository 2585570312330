import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { StaticImage } from 'gatsby-plugin-image'

const logosHealthcare = ({ width }) => {
    return (
        <Fragment>
            <div className='container-1 logos-section mobile-padding-reset'>
                <div className='text-center mb-2'>
                    <p className='pb-3'>
                        <a className='h4-like fw-600' href='/customers/'>
                            Healthcare Customers Love & Trust Lenses &rarr;
                        </a>
                    </p>
                </div>
                <div className='d-flex flex-wrap mx-3 mobile-padding-reset  justify-content-center align-items-center'>
                    <div className='col-lg col-sm-3 col-6 text-center mobile-padding-reset'>
                        <a href='/customers/'>
                            <StaticImage
                                imgClassName='p-1'
                                className={`${width ? `w-${width}` : 'w-100'}  zoom-on-hover p-1`}
                                src='../../resources/images/clients/grid/gray/elsevier.svg'
                                placeholder='tracedSVG'
                                alt='Elsevier'
                            />
                        </a>
                    </div>

                    <div className='col-lg col-sm-3 col-6 text-center mobile-padding-reset  '>
                        <a href='/customers/' className='w-100 d-block'>
                            <StaticImage
                                className={`${width ? `w-${width}` : 'w-100'}  zoom-on-hover`}
                                src='../../resources/images/clients/grid/gray/babylon.svg'
                                placeholder='tracedSVG'
                                alt='Babylon'
                            />
                        </a>
                    </div>

                    <div className='col-lg col-sm-3 col-6 text-center mobile-padding-reset '>
                        <a href='/customers/'>
                            <StaticImage
                                className={`${
                                    width ? `w-${width}` : 'w-100'
                                }  zoom-on-hover mw-100 `}
                                src='../../resources/images/clients/grid/gray/illumina.svg'
                                placeholder='tracedSVG'
                                alt='Illumina'
                            />
                        </a>
                    </div>

                    <div className='col-lg col-sm-3 col-6 text-center mobile-padding-reset '>
                        <a href='/customers/'>
                            <StaticImage
                                className={`${
                                    width ? `w-${width}` : 'w-100'
                                }  zoom-on-hover mw-100 `}
                                src='../../resources/images/clients/grid/gray/nuvo.svg'
                                placeholder='tracedSVG'
                                alt='Nuvo'
                            />
                        </a>
                    </div>
                    <div className='col-lg col-sm-3 col-6 text-center mobile-padding-reset '>
                        <a href='/customers/'>
                            <StaticImage
                                className={`${width ? `w-${width}` : 'w-100'}  zoom-on-hover`}
                                src='../../resources/images/clients/grid/gray/cdc.svg'
                                placeholder='tracedSVG'
                                alt='CDC'
                            />
                        </a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default logosHealthcare

logosHealthcare.propTypes = {
    width: PropTypes.number,
}
