import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import AboveFooterSection from '../components/AboveFooterSection'
import { StaticImage } from 'gatsby-plugin-image'

import LogosHealthcare from '../components/partials/Logos-healthcare'
import Spacing from '../components/partials/Spacing'

import Layout from '../components/Layout'
const apacheKafkaForHealthCare = () => {
  const data = useStaticQuery(graphql`
    query HealthcareSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Healthcare" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <StaticImage
          className="home-fixed-top"
          src="../resources/images/homepage/hero_lens.svg"
          placeholder="blurred"
          alt="Hero Lense"
        />
        <div className="container-1 hero-margin mobile-margin-bottom">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-6 col-12">
              <h1 className="pr-5">
                Accelerate event-driven healthcare through safe developer
                self-service for Apache Kafka
              </h1>
              <div className="pr-5 pt-3 pb-2">
                <p className="hero-description-dark pr-5 mobile-padding-reset">
                  You can’t develop innovative applications around{' '}
                  <b> streaming data in healthcare</b>, if your Kafka project is
                  stuck on regulatory box-checking.
                </p>
              </div>
              <div className="roll-button mt-2">
                <a className="bg-red text-white" href="/start/">
                  <span data-hover="Get started">Get started</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half"
                src="../resources/images/apache_kafka_for_healthcare/hero-image.svg"
                placeholder="tracedSVG"
                alt="Apache Kafka for Healthcare"
              />
            </div>
          </div>
        </div>
      </section>
      <Spacing />

      <LogosHealthcare width={75} />

      <Spacing />

      <section className="bg-cyan mobile-margin-top">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-12 text-center">
              <h2 className="h1-like pb-1">
                Paralyzed by concerns about patient data in Kafka?
              </h2>{' '}
              {/* <p className="cera-pro f-24 fw-400 pb-4">
                  In 2021, an event-driven architecture doesn’t just save
                  millions of dollars - it can save millions of lives:
                </p> */}
            </div>
            <div className="col-10 text-center">
              <p className="mx-auto mt-4">
                In 2021, an event-driven architecture doesn’t just save millions
                of dollars - it can save millions of lives:
              </p>
              <p>
                <span className="primary-text fw-900"> 1. </span> How can
                engineers safely access health data streaming through Kafka,
                without entering a regulatory minefield?
              </p>
              <p>
                <span className="primary-text fw-900"> 2. </span>
                How can a team quickly and confidently build stream processing
                applications without relying on one Kafka expert?
              </p>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-10">
              <div className="text-center">
                <h2 className="h1-like">
                  Apache Kafka use cases in healthcare
                </h2>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <StaticImage
                  className="w-100 "
                  src="../resources/images/apache_kafka_for_healthcare/connected-health-and-iot.svg"
                  placeholder="tracedSVG"
                  alt="Connected Health and IOT"
                />
                <div className="pb-4 px-3">
                  <h3 className="text-center fw-700 cera-pro f-20  lh-29 text-uppercase">
                    Connected health and IOT
                  </h3>
                  <p>
                    Biotech and remote monitoring require ingestion of real-time
                    IoT data.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1 mobile-margin-top">
              <div className="border-top-0 rounded-1">
                <StaticImage
                  className="w-100 "
                  src="../resources/images/apache_kafka_for_healthcare/automated-diagnosis.svg"
                  placeholder="tracedSVG"
                  alt="Automated Diagnosis"
                />
                <div className="pb-4 px-3">
                  <h3 className="text-center fw-700 cera-pro f-20  lh-29 text-uppercase">
                    Automated diagnosis
                  </h3>
                  <p className="pt-4 mt-1 ipad-padding-reset">
                    AI-driven digital doctors rely on a healthy
                    microservice-based architecture.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1 mobile-margin-top">
              <div className="border-top-0 rounded-1">
                <StaticImage
                  className="w-100 "
                  src="../resources/images/apache_kafka_for_healthcare/big-data-analytics.svg"
                  placeholder="tracedSVG"
                  alt="Big data analytics"
                />
                <div className="pb-4 px-3">
                  <h3 className="text-center fw-700 cera-pro f-20  lh-29 text-uppercase">
                    Big data analytics in life science
                  </h3>
                  <p>Patient research, analytics and a single customer view.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center px-2">
            <h2 className="h1-like w-50 mx-auto mobile-w-100">
              Developer questions that Lenses answers for Kafka in healthcare
            </h2>
          </div>

          <div className="d-flex flex-wrap pt-5 my-5 align-items-center mobile-margin-reset">
            <div className="col-md-5 col-10 ">
              <h2>
                Troubleshooting my data and event-driven architecture is driving
                me crazy. Help?
              </h2>
              <p>
                Explore and debug events using SQL via a Kafka GUI or API.
                Lenses understands your data regardless of serialization: Avro,
                Protobuf, JSON, CSV and more.
              </p>
              <a
                className="primary-text text-nowrap"
                target="_blank"
                rel="noopener noreferrer"
                href="https://vimeo.com/443057175"
              >
                Watch how to navigate data with SQL{' '}
                <i className="red-pointer-right"></i>
              </a>
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="pt-2 w-100 mobile-margin-top-half"
                src="../resources/images/apache_kafka_for_healthcare/event-driven-architecture.svg"
                placeholder="tracedSVG"
                alt="Event Driven Architecture"
              />
            </div>
          </div>

          <div className="d-flex flex-wrap flex-column-reverse flex-sm-row pt-5 my-5 align-items-center mobile-margin-top">
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="pt-2 w-100 mobile-margin-top-half"
                src="../resources/images/apache_kafka_for_healthcare/regulations.svg"
                placeholder="tracedSVG"
                alt="Apache Kafka Regulations"
              />
            </div>
            <div className="offset-md-1 offset-0"></div>
            <div className="col-md-5 col-12 ">
              <h2>
                How can I make Kafka comply with regulations without custom
                tooling?
              </h2>
              <p>
                Build approval processes, audit trails, policies, data masking
                and RBAC around who can access what in Kafka to address
                regulations such as HIPAA.
              </p>
              <a
                className="primary-text text-nowrap"
                target="_blank"
                rel="noopener noreferrer"
                href="/blog/2021/03/architecting-apache-kafka-for-gdpr-compliance/"
              >
                Architecting Kafka for GDPR{' '}
                <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>

          <div className="d-flex flex-wrap pt-5 my-5 align-items-center mobile-margin-top">
            <div className="col-md-5 col-10 ">
              <h2>
                How can I build stream processing applications without having to
                do a PhD in Kafka?
              </h2>
              <p>
                Filter, aggregate, transform and reshape data with streaming
                SQL, deploying over your existing Kubernetes.
              </p>
              <a
                className="primary-text"
                target="_blank"
                rel="noopener noreferrer"
                href="/resources/streaming-sql-cheat-sheet-for-apache-kafka/"
              >
                Learn streaming SQL <i className="red-pointer-right"></i>
              </a>
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="pt-2 w-100 mobile-margin-top-half"
                src="../resources/images/apache_kafka_for_healthcare/streaming-apps.svg"
                placeholder="tracedSVG"
                alt="Streaming Apps"
              />
            </div>
          </div>

          <div className="d-flex flex-wrap my-5 flex-column-reverse flex-sm-row pt-5 align-items-center mobile-margin-top">
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="pt-2 w-100 mobile-margin-top-half"
                src="../resources/images/apache_kafka_for_healthcare/monolithic.svg"
                placeholder="tracedSVG"
                alt="Monolithic healthcare application"
              />
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-5 col-12 ">
              <h2>How can I break up my monolithic healthcare application?</h2>
              <p>Build CDC flows with full data observability into Kafka.</p>
              <a
                className="primary-text"
                target="_blank"
                rel="noopener noreferrer"
                href="/blog/2021/04/change-data-capture-apache-kafka-break-up-monolith/"
              >
                Change Data Capture with Kafka{' '}
                <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>

          <div className="d-flex flex-wrap pt-5 my-5 align-items-center mobile-margin-top">
            <div className="col-md-5 col-10 ">
              <h2>How can I trust what data has been accessed and by who?</h2>
              <p>
                Use role-based security authenticated with SSO and data access
                audit trails integrated into your SIEM.
              </p>
              <a
                className="primary-text"
                target="_blank"
                rel="noopener noreferrer"
                href="/blog/2020/12/life-of-pii-data-masking-and-compliance-for-apache-kafka/"
              >
                Life of PII for Apache Kafka{' '}
                <i className="red-pointer-right"></i>
              </a>
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="pt-2 w-100 mobile-margin-top-half"
                src="../resources/images/apache_kafka_for_healthcare/trust-the-data.svg"
                placeholder="tracedSVG"
                alt="Data integrity"
              />
            </div>
          </div>

          <div className="d-flex flex-wrap my-5 flex-column-reverse flex-sm-row pt-5 align-items-center mobile-margin-top">
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="pt-2 w-100 mobile-margin-top-half"
                src="../resources/images/apache_kafka_for_healthcare/kafka-setup.svg"
                placeholder="tracedSVG"
                alt="Kafka setup"
              />
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-5 col-12 ">
              <h2>How can I use open-source but with enterprise security?</h2>
              <p>
                Lenses is deployed on top of your existing Kafka infrastructure
                - cloud, self-hosted or on-prem - as an independent component.
                It interacts with the entire data ecosystem without Kafka
                lock-in.
              </p>
              <a
                className="primary-text"
                target="_blank"
                rel="noopener noreferrer"
                href="/cloud/managed-kafka-comparison-guide/"
              >
                Managed Kafka comparison guide{' '}
                <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Spacing />

      <section className="bg-kafka-for-healthcare mobile-margin-top">
        <div className="container-1 py-5">
          <div className="mx-auto w-20 mb-4 mobile-w-100 text-center">
            <StaticImage
              className="img-fluid"
              src="../resources/images/usecases/secure/babylon.png"
              placeholder="tracedSVG"
              alt="Babylon"
            />
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-6 col-10">
              <p className="text-white f-20 lh-35 text-center mb-5">
                “We are a healthcare company, the integration with Okta, RBAC
                and audit features are key for us and allow us to open up the
                platform to wider teams”
              </p>
            </div>
            <div className="col-sm-7 col-9">
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                <StaticImage
                  className="img-fluid dario-carneli"
                  quality={100}
                  src="../resources/images/apache_kafka_for_healthcare/jeremy.jpeg"
                  placeholder="tracedSVG"
                  alt="Jeremy Frenay"
                />
                <p className="f-18 fw-900 pl-3 pt-3 text-white">
                  {' '}
                  Jeremy Frenay, Lead Data Operations Engineer
                </p>
                <a
                  className="primary-text pt-4"
                  href="/videos/babylon-health-dataops-apache-kafka/"
                >
                  Watch the full story <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1">
          <div className="text-center">
            <h2 className="h1-like w-50 mx-auto mobile-w-100 mobile-margin-top-half">
              Architecture & Connectors for healthcare teams{' '}
            </h2>
          </div>
          <div className="mt-3 mb-5">
            <StaticImage
              className="img-fluid"
              src="../resources/images/apache_kafka_for_healthcare/diagram.svg"
              placeholder="tracedSVG"
              alt="Architecture"
            />
          </div>
          <div className="text-center">
            <a
              className="primary-text"
              target="_blank"
              rel="noopener noreferrer"
              href="/kafka-connectors.tsx"
            >
              See our whole Apache Kafka connector library{' '}
              <i className="red-pointer-right"></i>
            </a>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center">
            <h2 className="h1-like w-50 mx-auto">
              Healthcare regulations? <br />
              Covered:
            </h2>
          </div>
          <div className="d-flex flex-wrap justify-content-sm-between justify-content-center align-items-end">
            <div className="col-sm-3 col-7">
              <StaticImage
                className="img-fluid"
                src="../resources/images/usecases/secure/hipaa.svg"
                alt="GDPR"
              />
              <p className="text-center mt-2 f-20 fw-900 cera-pro">HIPAA</p>
            </div>
            <div className="col-sm-3 col-7">
              <StaticImage
                className="img-fluid"
                src="../resources/images/apache_kafka_for_finance/GDPR.svg"
                alt="GDPR"
              />
              <p className="text-center mt-2 f-20 fw-900 cera-pro">GDPR</p>
            </div>
            <div className="col-sm-3 col-7">
              <StaticImage
                className="img-fluid mb-5 mobile-margin-reset"
                src="../resources/images/apache_kafka_for_healthcare/fda.svg"
                placeholder="tracedSVG"
                alt="CCPA"
              />
              <p className="text-center mt-2 f-20 fw-900 cera-pro">FDA</p>
            </div>
          </div>
        </div>
      </section>
      <Spacing />

      <AboveFooterSection
        text={'Get started with Lenses over Kafka for healthcare:'}
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default apacheKafkaForHealthCare
